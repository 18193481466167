.create-app-popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(37, 169, 213, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 999;

    > .create-app-popup {
        background: #ffffff;
        border: 1px solid #f0f0f0;
        box-sizing: border-box;
        border-radius: 2px;
        width: 1122px;
        height: 677px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        > {
            .header {
                height: 70px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                > span {
                    &:first-child {
                        font-family: NunitoSans, sans-serif;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 41px;
                        letter-spacing: 0.0120588em;
                        color: #173432;
                        margin-left: 25px;
                    }

                    &:last-child {
                        margin-right: 28px;
                        cursor: pointer;
                    }
                }
            }

            .header-shadow {
                background: linear-gradient(180deg, #f2f4f5 0%, rgba(255, 255, 255, 0) 100%);
                mix-blend-mode: multiply;
                height: 11px;
            }

            .content {
                flex: 1;
                display: flex;
                box-sizing: border-box;

                > div {
                    flex-grow: 1;
                    display: flex;
                    align-items: flex-start;
                    padding-left: 25px;
                    box-sizing: border-box;
                    flex-direction: column;
                    max-height: 454px;
                    min-height: 454px;
                    overflow-y: auto;
                    max-width: 558px;

                    &.border {
                        flex-grow: 0;
                        box-sizing: border-box;
                        width: 1px;
                        min-height: 220px;
                        max-height: 380px;
                        border: 1px solid #e9e9e9;
                        margin: 74px 0 0 0;
                        padding: 0;

                        &.invisible-border {
                            opacity: 0;
                        }
                    }
                }
            }

            .fixed-height-250 {
                height: 250px;
            }

            .content > {
                div > {
                    .two-cols {
                        display: flex;
                        width: 100%;
                        margin-top: 30px;
                        margin-bottom: 19px;
                        box-sizing: border-box;
                    }

                    .one-col,
                    .group-col > .one-col {
                        display: flex;
                        width: 223px;
                        flex-direction: column;
                        margin-bottom: 0;
                        padding-left: 10px;
                        box-sizing: border-box;
                    }

                    .one-col.app-description {
                        width: 100%;
                    }
                }

                .selected-states {
                    margin: 0;
                    width: 100%;

                    > .one-col {
                        width: 100%;
                    }
                }

                div > {
                    .controls-col,
                    .group-col > .controls-col {
                        display: flex;
                        width: 100%;
                        margin-bottom: 19px;
                        padding-left: 10px;
                        box-sizing: border-box;
                        align-items: center;
                    }
                }
            }
        }
    }
}

.create-app-popup > .sections {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 41px 0 0 25px;
}

.sections {
    .simple-checkboxes-two-cols {
        display: flex;
        margin-bottom: 10px;
    }

    .age-and-bmi-header {
        display: flex;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 23px;
        color: #959595;
        margin-bottom: 30px;
    }
}

.create-app-popup-overlay > .create-app-popup > {
    .content {
        position: relative;
        > div {
            > {
                .controls-col > .simple-checkboxes-two-cols,
                .group-col > .controls-col > .simple-checkboxes-two-cols {
                    display: flex;
                    align-items: center;
                }

                .controls-col > .simple-checkboxes-two-cols > label,
                .group-col > .controls-col > .simple-checkboxes-two-cols > label {
                    margin: 0 10px;
                }

                .controls-col input[type="text"] {
                    width: 80px;
                }

                .group-col {
                    > .controls-col input[type="text"] {
                        width: 80px;
                    }

                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 19px;
                    box-sizing: border-box;
                }

                .one-col > .simple-checkboxes-two-cols {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    padding-left: 43px;
                    flex-wrap: wrap;
                    height: auto;
                    overflow-y: auto;

                    > .simple-checkbox-content {
                        box-sizing: border-box;
                        width: 50%;
                    }
                }

                .app-type {
                    margin-top: 14px;
                }

                .two-cols > div {
                    box-sizing: border-box;
                    flex-grow: 1;
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    max-width: 50%;
                    margin-left: 10px;

                    &:last-child {
                        padding-left: 40px;

                        > .age {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                }
            }

            input[type="text"] {
                width: 100%;
            }
        }

        .sub-surveys-massage {
            position: absolute;
            left: 436px;
            bottom: 185px;
            width: 251px;
            min-height: 52px;
            padding: 0;
            text-align: left;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
        }

        .age > span > input[type="text"] {
            width: 79px;
        }

        > div {
            label {
                font-family: NunitoSans, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 16px;
                display: flex;
                align-items: center;
                letter-spacing: 0.0120588em;
                color: #173432;
                margin-bottom: 10px;
            }

            > .sub-header {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 23px;
                display: flex;
                text-transform: uppercase;
                color: #959595;
                margin-top: 41px;
            }

            > .sub-survey-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-height: 360px;
                overflow-y: auto;
            }

            &:last-child > .sub-header {
                margin-bottom: 30px;
            }

            > {
                .sub-header {
                    margin-bottom: 30px;

                    .add-section {
                        padding-left: 17px;
                        cursor: pointer;
                    }
                }

                .disabled-sub-header {
                    opacity: 0.5;
                }

                .sections {
                    > .one-col-section {
                        width: 100%;
                        padding-left: 10px;
                        box-sizing: border-box;
                        margin-bottom: 27px;
                        display: flex;
                        align-items: center;
                    }

                    width: 100%;
                    overflow-y: auto;
                    padding-left: 10px;
                    box-sizing: border-box;
                    padding-right: 40px;

                    img.remove-section {
                        padding-left: 14px;
                        cursor: pointer;
                    }

                    span {
                        &.reorder-section {
                            position: relative;
                            left: -9px;
                        }

                        &.text-box {
                            width: 456px;
                        }
                    }
                }
            }

            > .scoring-list {
                width: 100%;
                display: flex;
                flex-direction: column;

                > .scoring-item {
                    width: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;

                    > span {
                        margin-right: 4px;
                    }

                    > .small-number-field {
                        width: 80px !important;
                        height: 44px;
                        padding: 10px 8px;
                        margin-right: 4px;
                        flex-grow: 2;
                    }

                    > .delete-button {
                        display: flex;
                        flex-direction: row-reverse;

                        > button {
                            width: 16px;
                            height: 16px;
                            margin-right: 26px;
                            padding: 0;
                            background: url("../../assets/images/close-icon.svg") no-repeat center;
                            background-size: cover;
                        }
                    }

                    > .drop-down-container {
                        width: 123px;
                        margin-right: 4px;

                        > .drop-down {
                            > input {
                                height: 44px;
                                padding: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer {
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 23px;

        .scoring-validation {
            color: #f35656;
            margin-right: 300px;
        }
    }

    .footer-shadow {
        height: 11px;
        background: linear-gradient(180deg, #f2f4f5 0%, rgba(255, 255, 255, 0) 100%);
        mix-blend-mode: multiply;
        transform: rotate(180deg);
    }
}

.controls-col .weight {
    margin-left: 10px;
}

.controls-col-high {
    margin-bottom: 10px;
}

.small-number-field {
    width: 80px !important;
}

.divider {
    margin-bottom: 30px !important;
}

.required {
    color: #f35656;
    font-weight: normal;
}

.create-app-button {
    position: relative;
    width: 202px;
    height: 43px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    padding-right: 0;
    padding-left: 0;
    svg {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
    }
    &.back {
        margin-right: 20px;
        background: #f2f3f3;
        color: #006485;
    }
}
