.numeric-range-container {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
}

.hint-container {
    flex-direction: row;
    margin-bottom: 10px;
}

.range-group {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;

    > .dash {
        margin-top: 16px;
        margin-left: 17px;
        margin-right: 17px;
    }
}

.numeric-range-container .range-inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    > {
        input {
            background: #f6f6f6;
            border: 1px solid #e9e9e9;
            box-sizing: border-box;
            border-radius: 4px;
            height: 55px;
            width: 200px;
            padding: 16px;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 23px;
            display: flex;
            letter-spacing: 0.0120588em;
        }

        label > input[type="checkbox"] {
            margin-top: 15px;
        }
        .invalid-input, .invalid-input:focus, .invalid-input:hover {
          border: red 1px solid;
        }
    }
}

.uneditable {
    margin-top: 10px;
    cursor: default;
    width: 448px !important;
    background: rgba(246, 246, 246, 0.01) !important;
}

.text-disabled {
    color: #bdbdbd;
}

.hint-text {
    color: #828282;
}
