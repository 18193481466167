body {
    overflow: hidden;
}

.row {
    display: flex;
    height: 100vh;
}

.security > .left-column {
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
}

.left-column > {
    header {
        align-self: flex-start;

        > {
            .app-logo {
                padding: 17px 0 0 24px;
                width: 176px;
                height: 43px;
            }

            .app-menu {
                display: none;
            }
        }
    }

    .security-form > {
        h1 {
            font-weight: 800;
            font-size: 32px;
            line-height: 41px;
            color: #173432;
        }

        .security-form-description {
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            margin-top: 30px;
        }
    }
}

.security .right-column {
    background: url("../../assets/images/security-bg.png") no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.left-column .security-form .form {
    .button {
        background: #006485;
        border-radius: 36px;
        width: 100%;
        height: 44px;
        font-size: 14px;
        letter-spacing: 0.0120588em;
        text-align: center;
        outline: none;
        border: 0;
        margin-top: 15px;
        color: #ffffff;
    }

    .disabled {
        background: #eaeaee;
        color: #979797;
    }

    .form-item {
        .field-error {
            border: 1px solid #fb4e4e;
        }

        .form-error {
            font-size: 15px;
            line-height: 16px;
            color: #fb4e4e;
            margin-top: 6px;
        }
    }
}
