.app-item {
    width: 1166px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    box-sizing: content-box;
    cursor: pointer;

    > div {
        > div {
            width: 100%;
            display: flex;
            font-family: NunitoSans, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #173432;
            padding-left: 10px;
            align-items: center;

            > {
                .left {
                    flex: 1;
                    display: flex;
                    align-items: center;

                    .internationalization {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-direction: row;

                        svg {
                            width: 22px;
                            height: 22px;
                            margin-right: 5px;
                        }

                        span {
                            font-size: 16px;
                            font-weight: bold;
                            color: #006087;
                            padding-top: 2px;
                        }
                    }
                }

                .right {
                    > div {
                        margin-right: 28px;
                        text-align: center;
                    }

                    flex: 2;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }

            &:first-child {
                .status-CONSTRUCT {
                    background: #f1f1f1;
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    margin-right: 12px;
                }

                .status-PUBLISH {
                    background: #f0b11d;
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    margin-right: 12px;
                }

                .status-ACTIVE {
                    background: #009900;
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    margin-right: 12px;
                }

                .status-ARCHIVED {
                    background: #d70b0b;
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    margin-right: 12px;
                }

                .age,
                .type {
                    margin-right: 30px;
                }

                .date {
                    width: 202px;
                }
            }

            &:last-child {
                font-family: NunitoSans, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 26px;
                color: #707172;
                display: flex;
                align-items: center;
                padding-left: 10px;
                width: 1055px;
                max-width: 1055px;
            }
        }

        &:last-child {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 72px;

            > img {
                cursor: pointer;

                &:first-child {
                    margin-left: 33px;
                    margin-right: 25px;
                }
            }
        }
    }
}

.app-item-container {
    position: relative;

    > {
        .app-versions {
            box-shadow: 0 0 36px rgba(128, 154, 152, 0.25);
        }

        .app-item-menu {
            background: #ffffff;
            box-shadow: 0 0 36px rgba(128, 154, 152, 0.25);
            border-radius: 4px;
            position: absolute;
            right: 0;
            z-index: 9999;
            top: 50px;
            width: 167px;

            > ul {
                margin-top: 22px;
                margin-left: 20px;
                margin-bottom: 10px;

                > li {
                    padding-bottom: 10px;
                    font-family: NunitoSans, sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    color: #45474b;
                    cursor: pointer;
                }
            }
        }
    }

    .loader {
        position: absolute;
        top: 25px;
        right: -35px;

        svg {
            width: 25px;
            height: 25px;
        }
    }
}

.menu-arrow,
.menu-dots {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hide-action {
    display: none;
}
