@import "../../../../../../theme/colors";

.question-item {
    position: relative;
    width: 367px;
    padding: 26px;
    border-radius: 4px;
    gap: 12px;
    border: 1px solid $builder-border-color;
    margin-bottom: 20px;
    cursor: pointer;
    background-color: $builder-white-color;
    transition: 0.3s ease;
    .content {
        > div {
            position: relative;
            .settings-buttons {
                position: absolute;
                right: -33%;
                top: 40%;
                transform: translateY(-50%);

                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 70px;
            }
        }
    }
    .optional {
        position: absolute;
        right: -70px;
        top: 30px;
        font-size: 16px;
        font-weight: 400;
        color: #52b5a6;
    }
    .hidden-button {
        position: absolute;
        right: -95px;
        top: 50px;
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        width: max-content;
    }
    .visibility-label,
    .visibility-label-selected {
        margin: 0;
    }
    .visibility-popup {
        top: 26px;
    }
    .drag-indicator {
        position: absolute;
        left: -5%;
        top: 5%;
    }

    &:hover {
        margin-top: -5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 2;
    }

    .question-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            font-size: 18px;
            font-weight: bold;
        }

        svg:hover {
            cursor: pointer;

            path {
                transition: 0.2s;
                fill: $builder-main-color;
            }
        }
    }

    .question-text {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.012em;
        text-align: center;
        padding: 0;
        margin: 0 0 25px;
        color: $primary-text-color;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
}

.question-group-content {
    position: relative;
    border-radius: 5px;
    padding: 25px;
    background: #fafafa;
    .question-item {
        transition: 0.2s;
        position: relative;
        max-width: 275px;
        background: white;
        padding: 15px;
        margin: 0 auto 25px;
        border-radius: 5px;
        border: 1px solid white;


        &:hover {
            margin-top: -5px;
        }
    }
}

.group-section {
  .question-item .content .settings-buttons {
    right: -88px;
  }
}

.dynamic-group {
  .question-item .content .settings-buttons {
    right: -98px;
  }
}

.question-item-selected {
    border: 1px solid $builder-main-color !important;
}

.question-group-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        transition: 0.3s;
        padding: 0;
        width: 50%;
        border-radius: 0;

        span {
            font-size: 14px;
        }

        &:hover {
            background: $builder-main-color-lighter;
        }
    }

    .action-first {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border-right: 1px solid $builder-additional-color;

        span {
            margin-left: 5px;
        }
    }

    .action-last {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }
}

.yes-no {
    button {
        background: #f6f6f6;
        border: 1px solid $builder-white-color;
        border-radius: 3px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.0120588em;
        color: $primary-text-color;
        margin-bottom: 20px;
        width: 100%;
        transition: 0.3s ease;

        &:hover {
            transform: scale(1.01);
        }
    }
}

.dynamic-group-question {
    padding: 8px 6px;
    width: 100%;
    background: #ffffff;
    border-radius: 2px;
    .question-item {
      border: none;
      margin-top: 16px;
      width: 270px;
      .content {
        width: 100%;
        .settings-buttons {
          right: -100px;
        }
      }
      .hidden-button {
        right: -85px;
      }
    }
    .question-group-actions {
        .action-first {
            border-radius: 25px;
        }
    }
    .dynamic-instance {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 1px solid #dfdfdf;
        border-radius: 3px;
        padding: 3px;
        margin-bottom: 30px;
        .instance-title {
            display: flex;
            width: 360px;
            align-items: center;
            justify-content: space-between;
            padding: 9px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 0.0120588em;
            border-top: none;
            color: $primary-text-color;
            > img {
                cursor: pointer;
            }
            .remove-icon {
                position: relative;
                width: 20px;
                height: 20px;
                cursor: pointer;
                transition: 0.1s ease-in-out;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
        .survey-questions-container {
            width: max-content;
            .survey-question {
                width: 302px !important;
                border: none;
                margin-bottom: 0;
                .question-text {
                    font-family: "Nunito Sans", sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                    padding: 10px 0;
                    text-align: left;
                    color: $primary-text-color;
                }
                .help-text {
                    font-size: 15px;
                    line-height: 15px;
                    margin-top: 7px;
                    margin-bottom: 15px;
                }
            }
        }
    }

    > div:first-child {
        margin-bottom: 20px;
    }

    .question-title {
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 21px;
        line-height: 30px;
        letter-spacing: 0.0120588em;
        color: $primary-text-color;
        box-sizing: border-box;
    }

    .add-new-question {
        margin-top: 16px;
        width: 171px !important;
    }

    .survey-section {
        width: 100%;
        position: relative;
    }
    .content {
        .group-question-container {
            top: 0 !important;
        }

        .add-more {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 42px;
            max-width: 304px;
            background: #f6f6f6;
            border-radius: 3px;
            cursor: pointer;
            margin: 0 auto;
            transition: background-color 0.1s ease-out;

            img {
                margin-right: 9px;
            }

            &:hover {
                background: #d5d5da;
            }
        }
    }
}

.masked-number {
    background: #f6f6f6;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 4px;
    height: 55px;
    width: 100%;
    margin-bottom: 19px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0120588em;
    color: #173432;
    padding: 16px;
}

[type="text"].masked {
    background: #f6f6f6;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 4px;
    height: 55px;
    width: 100%;
    margin-bottom: 19px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #173432;
}

.numeric-range-container {
    .range-group {
        flex-direction: column;
        .range-inputs {
            max-width: 400px;
            label {
                margin-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                input[type="checkbox"] {
                    margin: 0;
                }
            }
        }
    }
}

.phone-input {
    margin-bottom: 10px;
    input[type="tel"] {
        border: 1px solid #cacaca;
        &:hover {
            border: 1px solid #cacaca;
        }
    }
}

.question-dragging {
    border: 1px solid $builder-main-color !important;
}

.question-not-dragging {
    opacity: 0.5;
}
