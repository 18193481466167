@import "../../theme/colors";

.app-list-filter {
    width: 100%;
    display: flex;
    flex-direction: column;

    > {
        .search {
            background: #ffffff;
            height: 62px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            > div {
                display: flex;
                align-items: center;
                gap: 10px;
                font-family: NunitoSans, sans-serif;
                font-style: normal;
                color: $secondary-text-color;
                cursor: pointer;
            }

            > img {
                position: absolute;
                left: 43px;
            }

            button {
                margin-right: 25px;
            }

            > input[type="text"] {
                background: #eeeff3;
                border-radius: 28px;
                width: 453px;
                border: 0;
                height: 37px;
                padding: 7px 7px 7px 43px;
                font-family: NunitoSans, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 23px;
                box-sizing: border-box;
                margin-left: 29px;
                margin-right: 25px;

                &::placeholder {
                    color: #a9a9a9;
                }
            }
        }

        .filter {
            background: #f8f8f8;
            height: 45px;
            box-shadow: 0 1px 15px rgba(193, 193, 193, 0.25);
            margin-left: 29px;
            display: flex;
            align-items: center;
        }
    }
}
