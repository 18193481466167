@import "../../../../theme/colors";

.survey-builder-header {
    display: flex;
    padding: 15px 0;
    background: white;
    border-bottom: 1px solid $builder-border-color;

    .survey-back-item {
        width: 100%;
        max-width: 250px;
        text-align: center;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            svg {
                margin-right: 5px;

                path {
                    stroke: white;
                }
            }
        }

        button:hover {
            transition: 0.3s;
            background: $builder-main-color-lighter;
        }
    }

    .survey-header-buttons {
        width: calc(100% - 50px);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 25px;

        .activation-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100px;

            span {
                margin-right: 5px;
            }
        }

        .main-buttons {
            background: $secondary-button-color;
            max-width: 250px;
            border-radius: 25px;

            .header-button {
                padding: 2px 22px;
                width: 125px;
                font-size: 14px;
                font-weight: 400;
                background: $secondary-button-color;
                color: $primary-text-color;
            }

            .header-button:hover {
                color: $builder-main-color;
            }

            .active-button {
                background: $primary-button-color !important;
                color: white !important;
            }
        }
    }
}

.empty-header {
    width: calc(100% - 200px);
}
