.branchind-logic-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(37, 169, 213, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 999;

    > .modal-popup {
        background: #ffffff;
        border: 1px solid #f0f0f0;
        box-sizing: border-box;
        border-radius: 2px;
        width: 1017px;
        height: 678px;
        display: flex;
        flex-direction: column;

        .checkbox-container {
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 23px;
            display: flex;
            align-items: center;
        }

        > {
            .header {
                height: 70px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                > span {
                    &:first-child {
                        font-family: NunitoSans, sans-serif;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 41px;
                        letter-spacing: 0.0120588em;
                        color: #173432;
                        margin-left: 25px;
                        width: 93px;
                    }

                    &:last-child {
                        margin-right: 28px;
                        cursor: pointer;
                        width: 14px;
                    }
                }
            }

            .header-shadow {
                background: linear-gradient(180deg, #f2f4f5 0%, rgba(255, 255, 255, 0) 100%);
                mix-blend-mode: multiply;
                height: 11px;
            }

            .content {
                flex: 1;
                display: flex;
                box-sizing: border-box;

                > {
                    .left-column {
                        display: flex;
                        align-items: flex-start;
                        margin-top: 13px;
                        padding-left: 22px;
                        box-sizing: border-box;
                        flex-direction: column;
                        width: 323px;
                        overflow: hidden;
                        overflow-y: auto;
                        border-right: 1px solid #f0f0f0;
                        border-radius: 2px;
                    }

                    .right-column {
                        margin-top: 13px;
                        padding-left: 22px;
                        box-sizing: border-box;
                        flex-direction: column;
                        width: 323px;
                        overflow: hidden;
                        overflow-y: auto;
                        flex-grow: 1;
                    }
                }
            }

            .header > .search {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding-right: 41px;

                input[type="text"] {
                    width: 272px;
                    height: 37px;
                    background: #eeeff3;
                    border-radius: 28px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 23px;
                    padding-left: 45px;
                    color: #a9a9a9;
                }

                &::before {
                    content: url("../../assets/images/search-icon.svg");
                    position: relative;
                    top: 10px;
                    left: 34px;
                }
            }

            .content {
                > div {
                    > .sub-header {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 13px;
                        line-height: 23px;
                        display: flex;
                        align-items: center;
                        text-transform: uppercase;
                        color: #959595;
                        margin-top: 23px;
                    }

                    .content {
                        padding-right: 20px;
                        height: 536px;
                        overflow-y: auto;
                    }
                }

                .sections-info {
                    margin-top: 10px;
                }

                > .right-column {
                    .checkbox-container {
                        &:first-child {
                            margin-top: 22px;
                        }

                        margin-bottom: 0;
                        border: 0;
                    }

                    .checkbox-selected {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 15px;
                        line-height: 23px;
                        display: flex;
                        align-items: center;
                        color: #006485;
                    }
                }
            }
        }
    }
}
