* {
    box-sizing: border-box;
}

.builder-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
}

.builder-header {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    padding-left: 29px;
    background-color: #ffffff;

    img {
        cursor: pointer;
    }

    > .title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 41px;
        display: flex;
        align-items: center;
        letter-spacing: 0.0120588em;
        color: #173432;
        margin-left: 14px;
        width: 126px;
    }

    .save-button {
        width: 121px;
        height: 28px;
        font-size: 14px;
        line-height: 23px;
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        margin-left: 30px;
    }

    .buttons-group {
        button {
            &.active {
                background-color: #006485;
                color: #ffffff;
                width: 121px;
            }

            &.inactive {
                width: 100px;
            }
            &.hidden {
                display: none;
            }
            background: #f4f4f4;
            font-family: NunitoSans, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 23px;
            letter-spacing: 0.0120588em;
            color: #006485;
            padding: 0;
            height: 28px;
        }

        background: #f4f4f4;
        border-radius: 35px;
        width: max-content;
        max-width: 350px;
        height: 28px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .language-toggle {
        display: flex;
        align-items: center;

        button {
            font-family: NunitoSans, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 23px;
            background: #f4f4f4;
            color: #006485;
            padding: 0;
            height: 28px;
            width: 120px;
        }

        .esp-lang {
            margin-left: -20px;
        }

        .selected-lang {
            background: #006485;
            color: white;
            z-index: 1;
        }
    }
}

.buttons-group {
    max-width: 350px;
    width: max-content;
}

.builder-header {
    > div {
        position: relative;
    }

    .save-button::after {
        content: url("../../assets/images/mark-while.svg");
        position: absolute;
        right: 11px;
    }
}

.builder-header-separator {
    width: 100%;
    height: 4px;
    background: linear-gradient(180deg, #000000 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.04;
}

.builder-container aside {
    height: calc(100% - 139px);
    overflow-y: auto;

    &.left {
        width: 18%;
        padding-top: 27px;
        padding-left: 31px;
        background-color: #ffffff;
        padding-bottom: 20px;
    }

    .acceptance-criteria-title,
    .score-title {
        margin-top: 20px;
    }

    .acceptance-criteria-line,
    .score-line {
        margin-bottom: 20px;
        padding-left: 15px;
    }

    &.center {
        width: 45%;
        background-color: #fafafa;
        padding-top: 27px;
    }

    &.right {
        width: 40%;
        background-color: #ffffff;
        padding: 30px;
    }

    &.left {
        .app-info {
            width: 100%;
            padding-left: 15px;
            display: flex;
            margin-top: 15px;

            .app {
                flex-grow: 20%;
                width: 20%;
            }

            .age {
                flex-grow: 52%;
                width: 52%;
            }

            .version {
                flex-grow: 20%;
                width: 28%;
            }
        }

        .region {
            width: 100%;
            margin-top: 15px;
            padding-left: 15px;
        }

        .disqualifying-question {
            width: 100%;
            margin-top: 15px;
            padding-left: 15px;
            color: #173432;
            font-weight: bold;
            font-size: 15px;
        }

        .disqualifying-question-description {
            width: 100%;
            margin-top: 10px;
            padding-left: 15px;
            color: #173432;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            padding-right: 29px;
        }
    }

    .column-title {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 23px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #959595;
        width: 100%;
        margin-bottom: 10px;

        > span {
            &:first-child,
            &.appEdit {
                text-transform: uppercase;
            }

            &:last-child {
                padding-right: 29px;
            }
        }

        .simple-checkbox-content label {
            padding-left: 50px;
            color: #020202;
        }

        .add-answer-btn {
            cursor: pointer;
        }
    }
}

.survey-section-welcome {
    width: 431px;
    background: #e6f0f3;
    border-radius: 4px;
    padding: 32px 33px 36px 31px;
    margin: 0 auto 63px;

    > {
        .header {
            display: flex;
            justify-content: space-between;

            > span:first-child {
                font-style: normal;
                font-weight: 600;
                font-size: 21px;
                line-height: 30px;
                letter-spacing: 0.0120588em;
                color: #173432;
            }
        }

        .content {
            padding-top: 16px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #173432;
        }
    }
}

.survey-section {
    > {
        div {
            &:first-child {
                display: flex;
                justify-content: space-between;
            }

            > {
                .question-remove {
                    cursor: pointer;
                    z-index: 20;
                }

                .question-number {
                    width: 100%;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.0120588em;
                    color: #020202;
                }
            }
        }

        .header {
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 21px;
            line-height: 30px;
            letter-spacing: 0.0120588em;
            color: #173432;
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: center;

            > .optional {
                font-size: 16px;
                font-weight: 400;
                color: #52b5a6;
            }
        }

        .content {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .content {
        > div {
            width: 100%;
            position: relative;
        }
        .add-more {
            border-radius: 50%;
            background: #2f91b7;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 30px;
            width: 30px;
            height: 30px;
            &:hover {
                background: #fbaa1e;
            }
        }

        .plus-icon {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
        }
        .add-more-text {
            color: #2f91b7;
            font-size: 0.75em;
            text-align: center;
            display: block;
            cursor: pointer;
            background-color: white !important;
        }

        button {
            background: #f6f6f6;
            border: 1px solid #f0f0f0;
            box-sizing: border-box;
            border-radius: 3px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.0120588em;
            color: #173432;
            margin-bottom: 20px;
            width: 100%;
        }
    }
}

.note > .header {
    font-size: 16px;
    text-align: left;
}

.radio > .content .radio-container {
    position: relative;
    padding: 10px 10px 10px 62px;
    cursor: pointer;
    user-select: none;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    color: #173432;
    min-height: 55px;
    background: #ffffff;
    border: 1px solid #d7d8e8;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 15px;
        left: 16px;
        height: 24px;
        width: 24px;
        background-color: #ffffff;
        border: 1px solid #006485;
        border-radius: 50%;
    }

    &:hover input ~ .checkmark {
        background-color: #fafafa;
    }

    input:checked ~ .checkmark {
        background-color: #006485;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        top: 7px;
        left: 7px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }
}

.survey-section > .content input {
    &[type="text"] {
        background: #f6f6f6;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        border-radius: 4px;
        height: 55px;
        width: 100%;
        margin-bottom: 19px;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        display: flex;
        align-items: center;
        letter-spacing: 0.0120588em;
        color: #173432;
    }

    &.masked-number {
        background: #f6f6f6;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        border-radius: 4px;
        height: 55px;
        width: 100%;
        margin-bottom: 19px;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        display: flex;
        align-items: center;
        letter-spacing: 0.0120588em;
        color: #173432;
        padding: 16px;
    }

    &[type="text"].masked {
        background: #f6f6f6;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        border-radius: 4px;
        height: 55px;
        width: 100%;
        margin-bottom: 19px;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        color: #173432;
    }
}

.dropdown > .content {
    input[type="text"] {
        width: 100%;
    }

    img {
        top: 25px;
    }
}

.file-upload > .content > input[type="text"] {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0120588em;
    color: #173432;
    width: 100%;
    margin-bottom: 19px;
    cursor: pointer;
}

.builder-container aside.right {
    .column-title {
        padding-bottom: 6px;
    }

    .question {
        label {
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            letter-spacing: 0.0120588em;
            color: #173432;
            margin-bottom: 10px;
            padding-top: 16px;
        }

        input[type="text"] {
            width: 100%;
        }
    }

    .answers {
        width: 100%;
        margin-top: 45px;

        > {
            .header {
                width: 100%;
                height: 70px;
                background-color: #f7f7f7;
                display: flex;
                align-items: flex-end;
                padding-left: 19px;
                border-bottom: 1px solid #eeeeee;

                > div {
                    width: 82px;
                    height: 51px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    user-select: none;

                    &.active {
                        background-color: #ffffff;
                        border-radius: 15px 15px 0 0;
                    }
                }
            }

            .sub-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 37px;
                padding-left: 30px;
                padding-bottom: 30px;

                .column-title {
                    width: 110px;
                    height: 23px;
                    display: flex;
                    align-items: center;

                    > span {
                        position: relative;
                        top: 2px;
                    }
                }
            }

            .photo-cropping-checkbox {
                line-height: 23px;
                padding-top: 10px;
                padding-left: 30px;
            }

            .content > .answer-group {
                margin-left: 30px;
                margin-bottom: 5px;

                > {
                    div:first-child {
                        margin-bottom: 10px;
                    }

                    .controls {
                        > {
                            div,
                            a > div {
                                display: flex;
                                align-items: center;
                            }
                        }

                        img {
                            margin-left: 10px;
                            cursor: pointer;
                        }

                        > div > a {
                            display: flex;
                        }

                        .text {
                            width: 296px;
                        }

                        .weight {
                            width: 45px;
                            margin-left: 6px;
                            padding: 2px;
                            &::placeholder {
                                padding: 14px;
                            }
                        }

                        > {
                            div > .feedback,
                            a > div > .feedback {
                                width: 448px;
                                margin-top: 7px;
                                padding-left: 49px;
                            }

                            div.feedback-container,
                            a > div.feedback-container {
                                position: relative;
                                margin-bottom: 15px;
                            }

                            div.feedback-container > img,
                            a > div.feedback-container > img {
                                position: absolute;
                                top: 24px;
                                user-select: none;
                            }

                            div > .feedback {
                                &::before {
                                    content: url("../../assets/images/comment.svg");
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }

                                &::placeholder {
                                    color: #949494;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 15px;
                                    line-height: 23px;
                                    display: flex;
                                    align-items: center;
                                    letter-spacing: 0.0120588em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.add-new-question {
    background: #006485;
    border-radius: 24px;
    height: 28px;
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto 20px;

    > div {
        display: flex;
        align-items: center;

        > {
            span {
                &:first-child::before {
                    content: url("../../assets/images/plus-white.svg");
                    padding-right: 10px;
                }

                display: flex;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 23px;
                letter-spacing: 0.0120588em;
                color: #ffffff;
                padding-left: 13px;
                padding-right: 13px;
                cursor: pointer;
            }

            i.border {
                border-left: 1px solid #a8a8a8;
                height: 10px;
                display: inline-block;
                padding: 0;
            }
        }
    }
}

.survey-save-button {
    width: 147px;
}

.app-visible {
    margin-left: 50px;
    color: #006485;

    > div {
        display: flex;
        align-items: center;
    }

    img {
        margin-left: 10px;
    }
}

.status-popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 999;
    background-color: rgba(37, 169, 213, 0.1);

    > .status-popup {
        width: 564px;
        background-color: #ffffff;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .header {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 41px;
            letter-spacing: 0.0120588em;
            color: #173432;
            margin-bottom: 30px;
        }

        .content {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            color: #45474b;
            margin-bottom: 30px;
        }

        .buttons {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            > div {
                width: 82px;
                height: 36px;
                box-sizing: border-box;
                border-radius: 25px;
                cursor: pointer;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 28px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.0120588em;
                color: #006485;
                justify-content: center;
            }
        }

        .button-yes {
            background: #ffffff;
            border: 1px solid #006485;
            margin-right: 27px;
        }

        .button-no {
            background: #006485;
            border: 1px solid #006485;
            color: #ffffff !important;
        }

        .container {
            width: 484px;
            margin: 0 auto;
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
}
