.sub-survey {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px 0 10px 25px;
    margin-right: 10px;
    position: relative;
    border-radius: 8px;

    &.active-sub-survey {
        background-color: rgba(0, 100, 133, 0.1);
    }

    .sub-survey-name {
        display: flex;
        flex-direction: column;

        input {
            width: 445px !important;
            height: 55px;
        }
    }

    .sub-survey-show {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .drop-down-container {
            width: 200px;
        }

        span {
            margin-right: 8px;
        }

        .previous-survey-name {
            color: #989898;
            margin-left: 18px;
        }
    }

    .delete-survey {
        position: absolute;
        cursor: pointer;
        top: 58px;
        right: 15px;
    }
}
