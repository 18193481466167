.questions-list {
    .group-section, .dynamic-group {
        border-radius: 4px;
        width: 450px;

        > div:first-child {
            margin-bottom: 20px;
        }

        .question-title {
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 16px;
            letter-spacing: 0.0120588em;
            display: flex;
            align-items: center;
        }

        .add-new-question {
            margin-top: 16px;
            width: 171px !important;
        }

        .survey-section {
            width: 100%;
        }
        .instance-title {
            display: flex;
            align-items: center;
            border-top: 1px solid #ddd;
            padding: 4px 0;
            color: #000;

            span {
                font-size: 18px;

                &:first-child {
                    margin-right: 8px;
                    color: #555;
                }
            }
        }
    }
}

.survey-section .content > .phone-input {
    margin-bottom: 10px;
}

.group-section .feedback-item {
    width: 317px !important;
}

.visibility-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #006485;
    cursor: pointer;
    width: 92px;
    height: 25px;
    margin: 10px 0 10px 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 11px;
    padding-right: 9px;
}

.visibility-label-selected {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #ffffff;
    cursor: pointer;
    width: 92px;
    height: 25px;
    background: #006485;
    border-radius: 64px;
    margin: 10px 0 10px 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 11px;
    padding-right: 9px;
}

.question-container {
    position: relative;
}

.visibility-popup {
    background: #ffffff;
    box-shadow: 0 0 36px rgba(128, 154, 152, 0.25);
    border-radius: 4px;
    position: absolute;
    top: 50px;
    padding: 30px 25px;
    z-index: 55;

    > {
        .header,
        .content {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
        }
    }

    .header,
    .content {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
    }

    .header {
        color: #707172;
    }

    .content {
        color: #173432;

        .plus-icon {
            font-weight: bold;
        }
    }

    .popup-section {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.survey-section {
    padding: 27px;
    width: 367px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 0 auto 20px;
    cursor: pointer;
    border: 1px solid transparent;
}

//.upload,
//.multi-upload {
//  width: 400px;
//}

.survey-section-selected,
.drag-active {
    border: 1px solid #006485;
    border-radius: 4px;
}

.survey-section .question-conditions {
    margin-bottom: 7px;
    position: relative;

    input[type="text"] {
        width: 100%;
    }
}

.survey-question-container {
    position: relative;
    .drag-indicator {
        position: absolute;
        top: -100%;
        left: -13%;
        cursor: pointer;
    }
    .visibility-drag-indicator {
        position: absolute;
        top: -40%;
        left: -13%;
        cursor: pointer;
    }
    .group-drag-indicator {
        position: absolute;
        top: -100%;
        left: -16%;
        cursor: pointer;
    }
}

.survey-question-container .feedback-item {
    padding: 27px 32px;
    max-width: 431px;
    background: #e6f0f3;
    border-radius: 4px;
    flex: none;
    order: 1;
    align-self: center;
    margin: 0 auto 20px;
}

.group-section .feedback-item {
    padding: 27px 32px;
    max-width: 100%;
    background: #e6f0f3;
    border-radius: 4px;
    flex: none;
    order: 1;
    align-self: center;
    margin: 0 auto 20px;
}

.survey-question-container .feedback-item {
    .feedback-title {
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        width: 365px;
        line-height: 30px;
        letter-spacing: 0.0120588em;
        color: #173432;
        min-height: 30px;
        margin-bottom: 16px;
    }

    .feedback-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
    }
}
