.password-form-item {
    margin-bottom: 21px;
    position: relative;

    label {
        font-size: 15px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.0120588em;
        color: #020202;
    }

    input {
        height: 55px;
        background: #f6f6f6;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        margin-top: 9px;
        padding: 16px 40px 16px 16px;
        font-size: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .show-password,
    .hide-password {
        position: absolute;
        right: 17px;
        top: 40px;
        width: 22px;
        height: 22px;
        cursor: pointer;
    }

    .form-error {
        color: #fb4e4e;
    }

    .field-error {
        border: 1px solid #fb4e4e;
    }
}
