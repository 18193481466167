.drop-down-items b {
    font-weight: bold;
}

.drop-down-items-portal {
    width: 200px;
    max-height: 306px;
    overflow-y: auto;
    position: absolute;
    background: #ffffff;
    box-shadow: 0 0 36px rgba(128, 154, 152, 0.25);
    border-radius: 4px;
    right: 0;
    left: 0;

    ul {
        > li {
            font-family: NunitoSans, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #45474b;
            padding-bottom: 10px;
            cursor: pointer;
            position: relative;
            text-align: left;
            display: flex;
            justify-content: space-between;
            padding-top: 16px;
            padding-left: 20px;
            &:hover {
                background: #d5d5da !important;
            }

            > span {
                margin-right: 20px;
                display: flex;
                white-space: pre-wrap;

                img:first-child {
                    margin-right: 8px;
                }
            }

            &.disabled {
                color: rgba(106, 106, 106, 0.47);
                cursor: not-allowed;
            }

            &.item-selected {
                font-family: NunitoSans, sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 26px;
                color: #006485;
            }

            &.filter-selected {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}
