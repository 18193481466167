.global-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(100, 100, 100, 0.38);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .global-loader-content {
        width: 500px;
        text-align: center;
        padding: 25px;
        background: white;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 1px #006087e6;

        .loader {
            margin-bottom: 25px;

            svg {
                width: 65px;
                height: 65px;
            }
        }

        h1 {
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 15px;
            color: #006087;
        }

        p {
            font-size: 18px;
            font-weight: 500;
            color: #2c2c2c;
        }
    }
}
