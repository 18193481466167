.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;

    .modal-content {
        background: white;
        padding: 15px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        width: 1017px;
        height: 678px;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        .modal-title {
            font-family: Nunito Sans, sans-serif;
            font-size: 28px;
            font-weight: 600;
            color: #173432;
            text-align: left;
        }
    }

    .modal-search-close {
        display: flex;
        align-items: center;
        > input {
            height: 30px;
        }
    }

    .modal-search-close img {
        margin-left: 10px;
        cursor: pointer;
    }

    .modal-body {
        display: flex;
        margin-top: 10px;
    }

    .sections-navigation {
        position: relative;
        flex: 1;
        border-right: 1px solid #ccc;
        padding-right: 10px;
        height: 580px;
        overflow-y: auto;

        .section-item {
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 5px;
            cursor: pointer;
            transition: background 0.3s;
        }

        .section-item:hover {
            background: #f0f0f0;
        }

        .section-item.selected {
            background: rgba(37, 169, 213, 0.1);
            font-weight: bold;
        }
    }

    .visibility-questions-list {
        position: relative;
        flex: 3;
        padding-left: 10px;
        height: 580px;
        overflow-y: auto;
        div {
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 5px;
            margin-bottom: 5px;
            background: #f9f9f9;
            cursor: pointer;
            transition: background 0.3s;
            .simple-checkbox-container,
            .simple-checkbox-content {
                background-color: transparent;
            }
        }

        .simple-checkbox-container {
            background-color: transparent;
        }

        div:hover {
            background: #f0f0f0;
        }
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
