//-- Builder Main Colors
$builder-main-color: #006485;
$builder-main-color-darker: #00506a;
$builder-main-color-lighter: #007ca4;
$builder-additional-color: #c5c5c5;
$builder-selection-color: #fafafa;
$builder-border-color: #f0f0f0;
$builder-white-color: #fff;

//-- Text Colors
$primary-text-color: #173432;
$secondary-text-color: #959595;

//-- Button Colors
$primary-button-color: #006485;
$secondary-button-color: #f4f4f4;
