html {
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    background-color: #fafafa;
    font-family: NunitoSans, sans-serif;
    font-style: normal;
    overflow: hidden;
    height: 100%;
}

.row {
    display: flex;
    height: 100vh;
}

.left-column {
    overflow-y: auto;
    width: 41%;
}

.right-column {
    overflow-y: auto;
    width: 59%;
}

.left-column > header > {
    .app-logo {
        padding: 17px 0 0 24px;
        width: 176px;
        height: 43px;
    }

    .app-menu {
        display: none;
    }
}

.video-column {
    width: 59%;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

#root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > {
        .App {
            box-sizing: border-box;
            width: 100%;

            > .wrapper {
                max-width: 100%;
            }
        }

        .wrapper {
            box-sizing: border-box;
            width: 100%;
            max-width: 100%;
            height: 100%;

            > .app-content {
                height: calc(100% - 202px);
            }
        }
    }
}

.color-purple {
    color: #ad1b5f !important;
}

.color-green {
    color: #1a7b74 !important;
}

.color-blue {
    color: #004a63 !important;
}

.color-gold {
    color: #846f45 !important;
}

.color-red {
    color: #d8384f !important;
}

button,
input[type="button"] {
    cursor: pointer;
}

button {
    padding: 4px 28px;
    background: #006485;
    border-radius: 25px;
    border: 0;
    letter-spacing: 0.0120588em;
    color: #ffffff;
    font-family: NunitoSans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    outline: none;
}

input {
    &[type="text"],
    &[type="number"] {
        background: #f6f6f6;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        border-radius: 4px;
        height: 55px;
        width: 182px;
        padding: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        display: flex;
        align-items: center;
        letter-spacing: 0.0120588em;
    }
}

textarea {
    background: #f6f6f6;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 4px;
    height: 55px;
    width: 182px;
    padding: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0120588em;
    resize: none;
}

.link {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.0120588em;
    color: #006485;
    cursor: pointer;
}

button.disabled {
    background: #eaeaee;
    color: #979797;
}

.animated-gradient {
    width: 100%;
    background: repeating-linear-gradient(to right, #006485 0%, #397974 50%, #006485 100%) 0 100%;
    background-size: 200% auto;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
}

@keyframes gradient {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -200% 0;
    }
}
