@import "../../../../../../theme/colors";

.relation-dialog-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000040;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .relation-dialog {
    border: 1px solid $builder-main-color;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.168627451);
    background: white;
    min-width: 800px;
    max-width: 1440px;
    height: 500px;
    border-radius: 5px;
    position: relative;

    .relation-dialog-header {
      display: flex;
      padding: 25px 15px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $builder-main-color;

      h1 {
        font-size: 20px;
        font-weight: bold;
        color: $primary-text-color;
      }

      svg {
        transition: 0.2s;
        width: 18px;
        height: 18px;
        path {
          fill: $primary-text-color ;
        }
      }

      svg:hover {
        transition: 0.2s;
        cursor: pointer;
        path {
          fill: $builder-main-color-lighter;
        }
      }
    }

    .relation-dialog-content {
      padding: 25px 15px;
      overflow: scroll;
      height: calc(100% - 150px);

      .relation-question {
        transition: 0.3s;
        display: flex;
        padding: 15px;
        border: 1px solid $builder-border-color;
        border-radius: 5px;
        margin-bottom: 15px;
        align-items: center;
        gap: 15px;
        cursor: pointer;

        .number {
          width: 30px;
        }

        .question {
          width: calc(100% - 105px);

          span {
            font-size: 12px;
            font-weight: 200;
            color: $secondary-text-color;
          }
          h2 {
            font-size: 18px;
            color: $primary-text-color;
          }
        }

        .selection {
          width: 45px;
          text-align: center;

          svg {
            width: 16px;
            height: 16px;
            path {
              fill: $builder-main-color;
            }
          }
        }
      }

      .relation-question:hover {
        transition: 0.3s;
        margin-top: -5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 2;
      }

      .relation-selected {
        transition: 0.3s;
        margin-top: -5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 2;
        cursor: default;
        border: 1px solid $builder-main-color;
      }
    }

    .relation-dialog-footer {
      padding: 0 15px;
      height: 80px;
      border-top: 1px solid $builder-main-color;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        transition: 0.3s;
      }

      button:hover {
        transition: 0.3s;
        background-color: $builder-main-color-lighter;
      }

      button:disabled {
        cursor: default;
        color: $secondary-text-color;
        background-color: $builder-border-color;
      }
    }
  }
}