.feedback-editor {
    padding: 0 35px;
    .column-title {
        > span {
            font-family: "Nimbus Sans", sans-serif;
            font-size: 13px;
            line-height: 23px;
            color: #959595;
            text-transform: uppercase;
        }
        margin-bottom: 25px;
    }
    .question-info {
        padding: 0 10px;
        .bolt-text {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            color: #173432;
        }

        .answer-title {
            margin-top: 20px;
        }

        .answer-text {
            margin-top: 10px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            color: #173432;
        }
    }

    .form {
        margin-top: 20px;
        width: 100%;
        padding: 0 10px;

        input[type="text"] {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}
