.active-dd-item {
    background-color: #e5e5e5;
}

.dd-wrapper {
    display: block !important;
    position: absolute;
    justify-content: left;
    top: 0;
    left: 0;
    z-index: 1000;
}

.dd-list {
    margin-top: 52px;
    background-color: white;
    box-shadow: 0 0 10px 0 #dcdcdc;
    border-radius: 2px;
    width: 115px;
    color: #45474b;
}

.dd-list-item {
    color: #45474b;
    padding: 8px;
}

.selected-dd-item {
    background-color: #f8f8f8;
}
