.sections-info {
    margin-top: 17px;
    margin-left: 10px;
    width: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    > {
        .section-item-active {
            background-color: rgba(37, 169, 213, 0.1);

            > span:first-child {
                font-weight: bold;
            }
        }

        .section-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            min-height: 48px;
            max-height: max-content;
            align-items: center;
            border-radius: 5px 0 0 5px;
            cursor: pointer;
            user-select: none;

            > span {
                &:first-child {
                    font-style: normal;
                    font-size: 15px;
                    line-height: 23px;
                    color: #173432;
                    padding-left: 15px;
                }

                &:last-child {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 23px;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    letter-spacing: 0.0120588em;
                    color: #959595;
                    padding-right: 29px;
                }
            }
        }
    }
}
