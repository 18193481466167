.help-text {
    > span {
        text-decoration-line: underline;
    }
    text-align: center;
    margin-top: 14px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.0120588em;
    color: #818181;
    cursor: pointer;
    position: relative;
    max-width: 500px;

    .hint {
        text-decoration-line: none !important;
        color: #818181;
        background: #ffffff;
        border: 1px solid #f0f0f0;
        box-sizing: border-box;
        border-radius: 2px;
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 20px;
        z-index: 9999;
    }
}
