.feedback > {
    .question-info {
        margin: 0 20px;

        .question-text {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            color: #173432;
            margin-top: 10px;
        }

        .answer-title {
            margin-top: 20px;
        }

        .answer-text {
            margin-top: 10px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
            color: #173432;
        }
    }

    .form {
        margin-top: 20px;
        width: 100%;

        input[type="text"] {
            width: 100%;
        }

        textarea {
            width: 100%;
            height: 80px;
            font-size: 15px;
            line-height: 23px;
            display: flex;
            align-items: center;
            letter-spacing: 0.0120588em;
            color: #173432;
            font-family: NunitoSans, sans-serif;
            font-style: normal;
            font-weight: normal;
        }

        .field {
            margin-bottom: 20px;

            > label {
                margin-bottom: 10px;
                display: inline-block;
            }
        }
    }
}
