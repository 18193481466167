.photo-upload {
    position: relative;
    .upload-container {
        position: relative;
        background: #fafafa;
        border: 1px dashed #c0baba;
        border-radius: 5px;
        cursor: pointer;
        height: 400px;
    }

    .upload-label {
        display: block;
        height: 100%;
        width: 100%;
        cursor: pointer;
        .upload-input {
            display: none;
        }

        .label-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .title {
            font-family: "Nunito Sans", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 23px;
            text-align: center;
            margin-top: 17px;
            margin-bottom: 5px;
            color: #57575c;
        }

        .size-text {
            font-family: "Nunito Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 23px;
            text-align: center;
            color: #92939c;
        }
        .types-text {
            font-size: 13px;
        }
    }

    .preview-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        width: 100%;
        margin-top: 10px;
        .preview-wrapper {
            position: relative;
        }
        .preview-photo {
            width: 100px;
            height: 100px;
            object-fit: cover;
            cursor: pointer;
        }

        .remove-photo {
            position: absolute;
            top: 7%;
            right: 5%;

            width: 10px;
            height: 10px;
            cursor: pointer;
        }
    }

    .single-preview {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 400px;
        z-index: 99;
        background: #fff;
        border: none;
    }

    .single-photo {
        background: #fff;
    }

    .single-buttons {
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
        .btn-cancel {
            font-family: "Nunito Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            border: none;
            text-align: center;
            letter-spacing: 0.0120588em;
            width: 147px;
            padding: 8px;
            color: #57575c;
            cursor: pointer;
            background: #eaeaee;
            border-radius: 36px;
            margin-right: 10px;
        }

        .btn-save {
            font-family: "Nunito Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            border: none;
            text-align: center;
            letter-spacing: 0.0120588em;
            width: 147px;
            padding: 8px;
            color: #ffffff;
            cursor: pointer;

            background: #c84653;
            border-radius: 36px;
        }
    }
}
