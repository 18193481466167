.date-picker {
    display: flex;

    .drop-down {
        padding-left: 5px;
        padding-right: 5px;
    }

    .month-container input {
        max-width: 120px;
    }

    .day-container input,
    .year-container input {
        max-width: 80px;
    }

    .drop-down img {
        top: 26px;
    }
}
