html,
body {
    overflow: hidden;
}

.overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    height: 100%;
}
