.checkbox-password {
    input {
        display: none;
    }

    span {
        width: 12px;
        height: 12px;
        position: relative;
        top: 1px;
        display: inline-block;
        background: url("../../assets/images/checkbox_small.svg") no-repeat right center;
        background-size: contain;
    }

    input:checked + span {
        background: url("../../assets/images/checkbox_checked_small.svg") no-repeat right center;
        background-size: contain;
    }
}
