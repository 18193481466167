@import "../../../../../theme/colors";

.new-survey-dialog {
    position: absolute;
    background: #00000061;
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .new-survey-dialog-content {
        border: 1px solid $builder-main-color;
        box-shadow: 0 0 8px 2px #0000002b;
        background: white;
        width: 80%;
        max-width: 1440px;
        height: 90%;
        border-radius: 5px;
        position: relative;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 25px 15px;
            border-bottom: 1px solid $builder-border-color;

            span {
                font-size: 28px;
                font-weight: 600;
                color: $primary-text-color;
            }

            svg {
                transition: 0.3s;
                width: 18px;
                height: 18px;

                path {
                    fill: $primary-text-color;
                }
            }

            svg:hover {
                transition: 0.3s;
                cursor: pointer;

                path {
                    fill: $builder-main-color;
                }
            }
        }

        .header {
            display: flex;
            align-items: center;
            background: #f0f0f0;

            .tab-25 {
                width: 25%;
            }

            .tab-33 {
                width: 33.33%;
            }

            .tab {
                transition: 0.3s;
                cursor: pointer;
                padding: 15px;

                span {
                    transition: 0.3s;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: $secondary-text-color;
                }

                hr {
                    transition: 0.3s;
                    height: 4px;
                    border: none;
                    background: $secondary-text-color;
                    border-radius: 5px;
                    padding: 0;
                    margin: 5px 0;
                }
            }

            .selected-tab {
                cursor: default;
            }

            .selected-tab,
            .tab:hover {
                transition: 0.3s;
                background: white;

                span {
                    transition: 0.3s;
                    color: $builder-main-color;
                }

                hr {
                    background: $builder-main-color;
                }
            }
        }

        .body {
            padding: 15px;
            display: flex;
            height: calc(100% - 206px);
            overflow-y: scroll;

            .info-tab-body {
                display: flex;
                flex-direction: column;
                width: 50%;

                .info-tab-type {
                    display: flex;
                    gap: 5px;
                    margin-bottom: 25px;
                    align-items: baseline;

                    span {
                        font-size: 16px;
                        font-weight: 800;
                        color: white;
                        background: #006485;
                        padding: 5px 15px;
                        border-radius: 15px;
                    }
                }

                label {
                    margin-bottom: 5px;
                    font-size: 16px;
                    font-weight: bold;
                    color: $primary-text-color;
                    letter-spacing: 0.012em;
                }

                input {
                    margin-top: 0;
                    width: calc(100% - 50px);
                    margin-bottom: 15px;
                    border: 1px solid transparent;
                    transition: border-color 0.3s ease-in-out;
                    color: $primary-text-color;
                }

                input:hover,
                input:focus {
                    border-color: $builder-main-color;
                }

                input:disabled {
                    background-color: $builder-border-color;
                    border-color: transparent;
                    cursor: not-allowed;
                }
            }

            .acceptance-tab-body {
                display: flex;
                flex-direction: column;
                width: 50%;

                label {
                    margin-bottom: 5px;
                    font-size: 16px;
                    font-weight: bold;
                    color: $primary-text-color;
                    letter-spacing: 0.012em;
                    width: 25%;
                }

                .ranges {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 35px;

                    input {
                        width: auto;
                        margin-top: 0;
                    }

                    svg {
                        cursor: pointer;

                        path {
                            transition: 0.3s;
                        }
                    }

                    svg:hover {
                        path {
                            transition: 0.3s;
                            fill: $builder-main-color;
                        }
                    }
                }

                .states-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    label {
                        margin-bottom: 0 !important;
                    }

                    button {
                        padding: 0 12px;
                    }
                }

                .states {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    gap: 5px;

                    .state-item {
                        cursor: pointer;
                        padding: 5px;
                        width: calc(100% / 9 - 4.5px);
                        background: #f0f0f0;
                        color: #006485;
                        text-align: right;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        svg {
                            path {
                                fill: $builder-border-color;
                            }
                        }
                    }

                    .selected-state {
                        background: $builder-main-color;
                        color: $builder-border-color;
                    }
                }
            }

            .scoring-tab-body {
                display: flex;
                width: 100%;

                .scoring-separator {
                    width: 50px;
                    position: relative;
                    display: flex;

                    .vl {
                        width: 2px;
                        border-radius: 5px;
                        background-color: $builder-border-color;
                        margin: 0 auto;
                    }
                }

                .scoring-tab-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    border-bottom: 1px solid $builder-border-color;
                    gap: 5px;

                    .scoring-tab-title {
                        width: calc(33.33% - 3.33px);
                        color: $primary-text-color;
                        text-transform: capitalize;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    .scoring-tab-error {
                        width: calc(33.33% - 3.33px);
                        color: red;
                        font-weight: bold;
                        text-align: right;
                    }

                    .add-scoring-item {
                        width: calc(33.33% - 3.33px);

                        button {
                            padding: 2px 15px;
                            transition: 0.3s;
                            display: flex;
                            align-items: center;

                            svg {
                                margin-right: 10px;
                            }
                        }

                        button:hover {
                            transition: 0.3s;
                            background-color: $builder-main-color-lighter;
                        }
                    }
                }

                .scoring-tab {
                    display: flex;
                    width: calc(50% - 25px);
                    flex-direction: column;

                    .scoring-items {
                        padding: 5px;
                        border-radius: 5px;
                        border: 1px solid transparent;

                        .scoring-item {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            margin-bottom: 10px;

                            .score-item-text {
                                color: $secondary-text-color;
                                width: 10%;
                            }

                            .score-item-title {
                                font-weight: 500;
                            }

                            .scoring-text {
                                width: calc(15% - 5px);
                            }

                            .between-scoring {
                                width: 50%;
                                display: flex;
                                gap: 5px;
                                align-items: center;

                                input {
                                    margin-top: 0;
                                    width: 40%;
                                }

                                .and-text {
                                    width: 20%;
                                    text-align: center;
                                    color: $secondary-text-color;
                                }
                            }

                            .drop-down-container {
                                width: 25%;
                            }

                            .score-item-input {
                                margin-top: 0;
                                width: 50%;
                            }

                            .score-item-scoring {
                                width: 15%;
                            }

                            .score-item-remove {
                                width: 5%;
                                text-align: center;

                                svg {
                                    transition: 0.3s;
                                    cursor: pointer;
                                }

                                svg:hover {
                                    transition: 0.3s;

                                    path {
                                        fill: $builder-main-color;
                                    }
                                }
                            }

                            input {
                                margin-top: 0;
                                width: 100%;
                                padding: 0 5px;
                                height: 35px;
                            }
                        }
                    }

                    .age-has-error,
                    .bmi-has-error {
                        border-color: red;
                    }
                }
            }

            .structure-tab-body {
                width: 100%;
                display: flex;

                .sub-surveys-list {
                    width: 50%;
                    padding: 15px;
                    background: $builder-selection-color;

                    .sub-surveys-list-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 15px;
                        padding-bottom: 5px;
                        border-bottom: 1px solid $builder-border-color;

                        h2 {
                            font-size: 14px;
                            font-weight: 600;
                            color: $secondary-text-color;
                        }

                        button {
                            padding: 0 9px;
                        }
                    }

                    .sub-surveys-cards {
                        overflow-y: auto;
                        height: calc(100% - 35px);
                        padding: 5px 15px;

                        .sub-survey-card {
                            transition: 0.3s;
                            padding: 15px;
                            border-radius: 5px;
                            border: 1px solid $builder-border-color;
                            background: white;
                            margin-bottom: 15px;

                            .card-head {
                                display: flex;
                                justify-content: space-between;
                                align-items: flex-start;
                                gap: 25px;

                                .card-title {
                                    width: calc(90% - 25px);

                                    label {
                                        font-size: 16px;
                                        font-weight: bold;
                                        color: $primary-text-color;
                                    }

                                    input {
                                        margin-top: 5px;
                                        width: 100%;
                                    }
                                }

                                .remove-sub-survey {
                                    transition: 0.3s;
                                    cursor: pointer;

                                    path {
                                        fill: $primary-text-color;
                                    }
                                }

                                .remove-sub-survey:hover {
                                    transition: 0.3s;

                                    path {
                                        fill: $builder-main-color;
                                    }
                                }
                            }

                            .show-row {
                                margin-top: 15px;
                                display: flex;
                                align-items: center;
                                gap: 5px;

                                label {
                                    font-size: 16px;
                                    font-weight: bold;
                                    color: $primary-text-color;
                                }

                                .drop-down-container {
                                    max-width: 200px;

                                    .drop-down {
                                        input {
                                            margin-top: 0;
                                            width: 200px;
                                        }
                                    }
                                }
                            }
                        }

                        .sub-survey-card:hover {
                            transition: 0.3s;
                            cursor: pointer;
                            border-color: $builder-main-color;
                        }

                        .sub-survey-card-selected {
                            border-color: $builder-main-color;
                        }
                    }
                }

                .sections-list {
                    width: 50%;
                    padding: 15px;
                    background: $builder-selection-color;

                    .sections-list-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 15px;
                        padding-bottom: 5px;
                        border-bottom: 1px solid $builder-border-color;

                        h2 {
                            font-size: 14px;
                            font-weight: 600;
                            color: $secondary-text-color;
                        }

                        button {
                            padding: 0 9px;
                        }
                        button:disabled {
                            background: $builder-additional-color;
                            cursor: auto;
                        }
                    }

                    .sections-cards {
                        overflow-y: auto;
                        height: calc(100% - 35px);
                        padding: 5px 15px;

                        .section-card {
                            transition: 0.3s;
                            display: flex;
                            padding: 15px;
                            border: 1px solid $builder-border-color;
                            border-radius: 5px;
                            background: white;
                            gap: 15px;
                            margin-bottom: 15px;

                            .drag-icon {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 25px;
                            }

                            .section-card-content {
                                width: calc(100% - 40px);
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                gap: 15px;

                                input {
                                    margin-top: 0;
                                    width: calc(100% - 40px);
                                }

                                .remove-section {
                                    transition: 0.3s;
                                    cursor: pointer;
                                    width: 25px;

                                    path {
                                        fill: $primary-text-color;
                                    }
                                }

                                .remove-section:hover {
                                    transition: 0.3s;

                                    path {
                                        fill: $builder-main-color;
                                    }
                                }
                            }
                        }

                        .section-card:hover {
                            transition: 0.3s;
                            cursor: pointer;
                            border-color: $builder-main-color;
                        }

                        .section-card-selected {
                            border-color: $builder-main-color;
                        }
                    }
                }
            }
        }

        .footer {
            position: absolute;
            bottom: 0;
            padding: 15px;
            left: 0;
            right: 0;
            border-top: 1px solid $builder-border-color;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            svg {
                width: 24px;
                height: 14px;
                margin-right: 15px;
            }

            .saved {
                path {
                    fill: $builder-main-color;
                }
            }

            .failed {
                path {
                    fill: red;
                }
            }

            button {
                transition: 0.3s;
                min-width: 150px;
            }

            button:hover {
                transition: 0.3s;
                background-color: $builder-main-color-lighter;
            }
        }
    }
}

.error-input {
    border: 1px solid red !important;
}

.if-not-disqualified {
    font-weight: 600;
    color: $secondary-text-color;
    font-size: 14px;
}

.remove-confirmation-dialog {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #0000006e;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    .dialog-content {
        background: white;
        border-radius: 5px;
        padding: 25px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;

        h1 {
            font-size: 24px;
            font-weight: 800;
            border-bottom: 1px solid $builder-border-color;
            padding-bottom: 25px;
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
        }

        p {
            font-size: 18px;
        }

        .confirmation-buttons {
            display: flex;
            gap: 25px;

            .confirm {
                transition: 0.3s;
            }

            .cancel {
                background-color: $builder-border-color;
                color: $primary-text-color;
            }

            .confirm:hover {
                transition: 0.3s;
                background-color: $builder-main-color-lighter;
            }

            .cancel:hover {
                transition: 0.3s;
                background-color: $builder-selection-color;
            }
        }

        svg {
            width: 30px;
            height: 30px;
        }
    }
}