@import "../../../../../theme/colors";

.sub-survey-item {
    margin-top: 20px;
    padding: 10px 5px;
    border: 1px solid $builder-border-color;
    border-radius: 5px;


    .sub-survey-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        h1 {
            font-size: 14px;
            font-weight: 600;
            color: $primary-text-color;
            text-transform: uppercase;
            width: calc(100% - 110px);
        }

        p {
            font-size: 12px;
            cursor: default;
            width: 105px;
            display: flex;
            justify-content: right;
            span {
                padding: 2px 5px;
                background-color: $builder-main-color;
                color: white;
                border-radius: 25px;
            }
        }


    }



    .sub-survey-sections {
        padding-left: 15px;
        margin-top: 25px;

        h2 {
            font-size: 14px;
            font-weight: 500;
            color: $secondary-text-color;
            text-transform: uppercase;
        }

        .section-item {
            margin-top: 15px;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .section-item:hover {
            transition: 0.2s;
            background: $builder-selection-color;
            cursor: pointer;
        }

        .active-section {
            background: $builder-selection-color;
            font-weight: bold;
            border-radius: 5px;
        }
    }
}
