@import "../../../../../theme/colors";

.question-types-list {
    width: 100%;
    height: 70px;
    background-color: #f7f7f7;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    padding: 0 20px;
    border-bottom: 1px solid #eeeeee;
    > div {
        width: 82px;
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        &.active {
            background-color: #ffffff;
            border-radius: 15px 15px 0 0;
        }
        .text-box-wrapper {
            position: relative;
        }
    }
}
