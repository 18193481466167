@import "../../../../../theme/colors";

.answer-settings {
    padding: 40px 28px;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .add-answer {
            display: flex;
            align-items: center;
            gap: 14px;
            cursor: pointer;
            > span {
                font-family: "Nimbus Sans", sans-serif;
                font-size: 13px;
                line-height: 23px;
                text-transform: uppercase;
                text-align: left;
                color: $secondary-text-color;
            }
        }
    }

    .answer-list {
        padding: 19px 12px;
        .answer-item {
            margin-bottom: 20px;
            .answer-count {
                margin-bottom: 10px;
            }
            > .content {
                .answer-main-info {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 15px;

                    .answer-text {
                        width: 300px;
                    }

                    .answer-score {
                        text-align: center;
                        width: 45px;
                        padding: 2px;
                    }

                    .answer-icons-container {
                        position: relative;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        gap: 9px;
                        cursor: pointer;

                        .disq-popup {
                            position: absolute;
                            right: 1%;
                            top: 33px;
                        }

                        button {
                            width: max-content;
                            background-color: transparent;
                            padding: 0;
                        }
                    }
                }

                .feedback-container {
                    position: relative;
                    > img {
                        position: absolute;
                        top: 18px;
                        left: 10px;
                        user-select: none;
                        cursor: pointer;
                    }
                    input[type="text"] {
                        max-width: 600px;
                        padding-left: 49px;
                    }
                    margin: 16px 0;
                }
            }

            .salesforce-value {
                input {
                    width: 100%;
                }
            }

            .additional {
                position: relative;
                .answer-icons-container {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;
                    .disq-popup {
                      top: 25px;
                      left: 170px;
                    }
                }
            }
        }
    }
    button {
      background-color: transparent;
      width: max-content;
      padding: 0;
    }
}

img {
    cursor: pointer;
}
