.survey-builder-section {
    padding: 43px 72px;
    .questions-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .feedback-item {
        padding: 27px 32px;
        width: 367px;
        background: #e6f0f3;
        border-radius: 4px;
        flex: none;
        order: 1;
        align-self: center;
        margin: 0 auto 20px;
        .feedback-title {
            font-style: normal;
            font-weight: 600;
            font-size: 21px;
            width: 365px;
            line-height: 30px;
            letter-spacing: 0.0120588em;
            color: #173432;
            min-height: 30px;
            margin-bottom: 16px;
        }

        .feedback-text {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
        }
    }

    .group-section {
        .feedback-item {
            width: 317px !important;
            padding: 27px 32px;
            max-width: 100%;
            background: #e6f0f3;
            border-radius: 4px;
            flex: none;
            order: 1;
            align-self: center;
            margin: 0 auto 20px;
        }
    }
}
