.settings {
  padding: 20px;

  &__title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  &__section {
    margin-bottom: 30px;

    &-title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    &-content {
      background-color: #fff;
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    &-item {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 10px;
      input[type='number'] {
        width: 200px;
      }
    }
    &-description {
      > span {
        color: red;
      }
    }
  }
}