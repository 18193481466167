@import "../../../../../theme/colors";

.survey-info-loader {
    text-align: center;

    svg {
        width: 50px;
    }
}

.survey-info-section {
    margin-bottom: 25px;

    .survey-info-data {
        padding: 0 15px;

        .survey-info-core {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
        }

        .survey-info-title {
            font-weight: 600;
            margin-bottom: 15px;
            font-size: 16px;
        }
    }
}

.survey-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    span {
        color: $secondary-text-color;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
    }

    .appEdit:hover {
        transition: 0.2s;
        cursor: pointer;
        color: $builder-main-color;
    }
}

.survey-acceptance-section {
    margin-bottom: 25px;

    .survey-acceptance-data {
        padding: 0 15px;

        .acceptance-criteria-item {
            margin-bottom: 15px;

            span {
                font-weight: 600;
            }

            .states {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .states-tooltip {
                    visibility: hidden;
                    position: absolute;
                    z-index: 100;
                    width: auto;
                    color: $primary-text-color;
                    font-size: 14px;
                    background-color: white;
                    border: 1px solid $builder-main-color;
                    border-radius: 10px;
                    padding: 15px 15px;
                    text-align: justify;

                    .state {
                        margin-bottom: 5px;
                    }
                }

                .show-states {
                    svg > path {
                        transition: 0.2s;
                        fill: $builder-additional-color;
                    }
                }

                .show-states:hover {
                    svg > path {
                        transition: 0.2s;
                        fill: $builder-main-color;
                    }

                    .states-tooltip {
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.survey-scoring-section {
    .survey-scoring-title {
        color: $secondary-text-color;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
    }

    .survey-scoring-content {
        padding: 15px;

        hr {
            margin-bottom: 15px;
            border: none;
            height: 0.5px;
            background: $builder-border-color;
        }

        .survey-scoring-tab {
            span {
                font-weight: 600;
            }

            .scoring-item {
                margin-bottom: 15px;
            }
        }
    }
}
