.uploader-file {
    .uploader-file-input {
        display: none;
    }

    .uploader-text-input {
        width: 448px;
        margin-top: 7px;
    }

    .error {
        border: 1px solid #ff6262;
    }

    .uploader-file-error {
        text-align: center;
        color: #f0566a;
    }
}
