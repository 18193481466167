.wrapper {
    position: fixed;
}
.wrapper > header {
    > {
        .mobile {
            display: none;
        }

        .desktop {
            display: flex;

            > {
                .left-column {
                    width: 200px;
                    order: 1;
                    display: flex;
                    align-items: center;
                }

                .right-column {
                    width: 480px;
                    display: flex;
                    align-items: center;
                    order: 3;
                    justify-content: flex-end;
                }

                .center-column {
                    flex: 1;
                    order: 2;
                }

                .left-column > a > .app-logo {
                    width: 176px;
                    height: 43px;
                    margin-left: 24px;
                }

                .center-column {
                    display: flex;
                    align-items: center;
                }

                .right-column > .user-profile {
                    height: 70px;
                    font-size: 16px;
                    line-height: 23px;
                    color: #22232e;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 17px 0 16px;
                    cursor: pointer;

                    > a {
                        font-size: 16px;
                        line-height: 23px;
                        color: #22232e;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    background: #ffffff;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    height: 80px;
    border-radius: 2px;
}

.profile-menu {
    position: absolute;
    top: 52px;
    width: 206px;
    height: 96px;
    background: #ffffff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 9999;

    > ul > li {
        height: 48px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
            background: #d5d5da;
        }

        &:first-child {
            border-bottom: 1px solid #d7d7d7;
        }

        > a {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #173432;
            text-decoration: none;
            padding-left: 16px;
            width: 100%;
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }
}

.app-menu {
    margin-left: 50px;

    > ul > li {
        display: inline-block;
        font-size: 16px;
        line-height: 78px;
        color: #22232e;
        padding-left: 26px;
        padding-right: 26px;
        height: 78px;
        cursor: pointer;
        box-shadow: inset 0 -20px 0 -10px #C4C4C4;
        background-color: #f2f2f2;
        &.active {
          background: rgba(139, 189, 217, 0.2);
          font-weight: bold;
        }
    }
}
