.progress-bar-wrapper {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    color: #959595;
    font-weight: bold;
    font-size: 13px;
    line-height: 23px;

    .header-shadow {
        background: linear-gradient(180deg, #f2f4f5 0%, rgba(255, 255, 255, 0) 100%);
        mix-blend-mode: multiply;
        width: 100%;
        height: 11px;
    }

    .progress-bar-item-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 25px 0 25px;
        margin-bottom: 9px;

        .progress-bar-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            cursor: pointer;
        }
    }
}

.progress-bar-item-wrapper .progress-bar-item {
    &.finished {
        color: #52b5a6;
    }

    &.in-progress {
        color: #006485;
    }
}

.progress-bar-item .progress-bar-indicator {
    &.not-finished {
        width: 260px;
        height: 5px;
        background: #c4c4c4;
    }

    &.finished {
        width: 260px;
        height: 5px;
        background: #52b5a6;
    }

    &.in-progress {
        width: 260px;
        height: 5px;
        background: #006485;
    }

    &.long {
        width: 350px;
    }
}
