.modal-dialog > .content > .form {
    width: 307px;
}

.modal-dialog .form .form-item {
    margin-bottom: 21px;
    position: relative;
}

.modal-dialog .form .form-item label {
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0120588em;
    color: #020202;
}

.modal-dialog .form .form-item input {
    height: 55px;
    background: #f6f6f6;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    margin-top: 9px;
    padding: 16px 40px 16px 16px;
}

.modal-dialog .password-description {
    width: 315px;
    height: 60px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0120588em;
    color: #959595 !important;
}

.modal-dialog .password-description b {
    font-weight: bold;
}

@media (max-width: 1250px) {
    .modal-dialog > .content > .form {
        width: 300px;
    }
}
