.survey-section,
.survey-builder-section {
    .multiSelectContainer {
        width: 304px;
        max-width: 100%;
      .searchWrapper {
            background: #f6f6f6;
            border: 1px solid #e9e9e9;
            border-radius: 4px;
            min-height: 55px;
            width: 313px;
            padding: 14px;
            max-width: 100%;
        input[type="text"] {
                border: none;
                margin: 0;
                padding: 0;
                display: inline;
                width: 82px;
                height: auto;
                &::placeholder {
                    color: #2c2c2c;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 23px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.0120588em;
                    opacity: 0.65;
                }
            }
            .chip {
                position: relative;
                font-family: "Nunito Sans", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 23px;
                background: #fff;
                letter-spacing: 0.0120588em;
                border-radius: 0;
                color: #000000;
                padding: 8px;
                &::after {
                    content: " ";
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    width: 10px;
                    height: 10px;
                    background: url("../../../assets/images/close.svg") 10px center;
                    background-size: contain;
                    cursor: pointer;
                }
            }
            .closeIcon {
                margin-left: 12px;
                opacity: 0;
                z-index: 99;
            }
            .icon_cancel {
                width: 20px;
                height: 11px;
                right: 8px;
            }
        }
        .optionListContainer {
            .optionContainer {
                .option {
                    position: relative;
                    font-family: "Nunito Sans", sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 16px;

                    display: flex;
                    align-items: center;
                    letter-spacing: 0.0120588em;

                    color: #020202;
                    padding: 15px 21px;
                    input[type="checkbox"] {
                        background: rgba(255, 255, 255, 0.2);
                        border: 1px solid #d5d5da;
                        box-sizing: border-box;
                        border-radius: 3px;
                        width: 24px;
                        height: 24px;
                        margin-right: 16px;
                        cursor: pointer;
                    }
                    &:hover {
                        background: #d5d5da;
                    }
                }
                .highlightOption {
                    background: none;
                }
            }
            .custom-option {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              .settings-buttons {
                position: static;
                margin-top: 10px;
                width: max-content;
                transform: translateX(10px);
                img {
                  margin-left: 5px;
                  cursor: pointer;
                }
              }
            }
        }
    }
}
