.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 20px;
}

.tooltip-text {
    visibility: hidden;
    background-color: #fafafa;
    font-family: NunitoSans, sans-serif;
    font-style: normal;
    color: #173432;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    font-weight: bold;
    height: auto;
    text-align: left;
    width: 150px;
    > h3 {
        margin-bottom: 10px;
    }
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
