.sub-survey-info {
    display: flex;
    flex-direction: column;
    margin-top: 45px;

    .section-item {
        &.hide {
            display: none;
        }
    }

    .column-title-sub-survey {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;

        span {
            color: black;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 23px;
            text-transform: uppercase;
        }

        div {
            .hide-show-button {
                margin-right: 11px;
                cursor: pointer;

                &.closed {
                    transform: rotate(180deg);
                }
            }

            .count {
                color: #959595;
            }
        }
    }
}
