.checkbox-container {
    position: relative;
    padding: 10px 10px 10px 62px;
    cursor: pointer;
    user-select: none;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    color: #173432;
    min-height: 55px;
    background: #ffffff;
    border: 1px solid #d7d8e8;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-container .checkmark {
    position: absolute;
    top: 14px;
    left: 16px;
    height: 24px;
    width: 24px;
    background-color: #fafafa;
    border: 1px solid #006485;
    box-sizing: border-box;
    border-radius: 3px;
}

.checkbox-container:hover input ~ .checkmark {
    background-color: #fafafa;
}

.checkbox-container input:checked ~ .checkmark {
    background-color: #006485;
}

.checkbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
