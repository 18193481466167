.filter-container {
    > {
        .filter-select {
            font-family: NunitoSans, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 23px;
            display: inline-block;
            align-items: center;
            letter-spacing: 0.0120588em;
            color: #707172;
            background: #ffffff;
            border: 1px solid #e0e2e3;
            border-radius: 16px;
            height: 24px;
            padding-left: 10px;
            padding-right: 10px;
            margin-right: 10px;
            white-space: nowrap;
            cursor: pointer;
            padding-top: 1px;
            position: relative;
        }

        .filter-items-container {
            position: absolute;
            width: 220px;
            background: #ffffff;
            box-shadow: 0 0 36px rgba(128, 154, 152, 0.25);
            border-radius: 4px;
            top: 35px;
            z-index: 1;
        }

        div > .filter-items {
            max-height: 306px;
            overflow-y: auto;

            ul {
                > li {
                    font-family: NunitoSans, sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    color: #45474b;
                    padding-bottom: 10px;
                    cursor: pointer;
                    position: relative;
                    padding-top: 14px;
                    padding-left: 20px;
                    &:hover {
                        background: #d5d5da !important;
                    }

                    &.filter-selected {
                        font-family: NunitoSans, sans-serif;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 26px;
                        color: #006485;
                    }

                    &.selected-multiselect::after {
                        content: url(../../assets/images/check-icon.svg);
                        position: absolute;
                        right: 25px;
                    }
                }
            }
        }
    }

    position: relative;
    user-select: none;

    .order-direction-asc {
        background: url(../../assets/images/filter-arrow-down.svg);
        width: 16px;
        height: 16px;
        display: inline-block;
        top: 3px;
        position: relative;
        left: 4px;
    }

    .order-direction-desc {
        background: url(../../assets/images/filter-arrow-down.svg);
        width: 16px;
        height: 16px;
        display: inline-block;
        top: 3px;
        position: relative;
        left: 4px;
        transform: rotate(180deg);
    }

    > .filter-items-container {
        box-sizing: border-box;

        input[type="text"]:first-child {
            margin: 26px 0 10px 20px;
        }

        .range-filter {
            > span {
                > input[type="text"] {
                    width: 79px;
                    margin: 0;
                }

                &.separator {
                    width: 22px;
                    display: inline-block;
                    text-align: center;
                }
            }

            margin-top: 26px;
            margin-left: 20px;
        }

        input[type="text"]::placeholder {
            font-family: NunitoSans, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 23px;
            display: flex;
            align-items: center;
            letter-spacing: 0.0120588em;
            color: #173432;
            opacity: 0.5;
        }
    }
}
