@import "../../theme/colors";

.survey-builder {
    color: $primary-text-color;

    .survey-builder-body {
        display: flex;

        .survey-builder-sidebar {
            width: 100%;
            padding: 25px 15px;
            background: white;
            max-width: 250px;
            height: calc(100vh - 150px);
            overflow-y: scroll;
            overflow-x: hidden;
        }

        .survey-builder-content {
            width: calc(100% - 50px);
            padding: 25px 15px;
            height: calc(100vh - 150px);
            overflow-y: scroll;
            overflow-x: hidden;
        }

        .survey-builder-editor {
            width: calc(100% - 200px);
            height: calc(100vh - 150px);
            padding: 25px 0;
            background: white;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        input {
            width: 100%;
            border: 1px solid transparent;
            margin-top: 10px;

            transition: border-color 0.3s ease-in-out;

            &:hover,
            &:focus {
                border-color: $builder-main-color-darker;
            }
        }
    }

    .test-container {
        height: 800px;
        overflow-y: scroll;
    }

    .test-question {
        transition: 0.3s;
        display: flex;
        background: gray;
        color: white;
        border-radius: 15px;
        max-width: 200px;
        margin: 0 auto 25px;
        height: 50px;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .test-question:hover {
        transition: 0.3s;
        background: red;
        cursor: pointer;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

@media (min-width: 1500px) {
    .survey-builder {
        color: $primary-text-color;

        .survey-builder-body {
            display: flex;

            .survey-builder-sidebar {
                max-width: 350px;
            }
        }
    }

}