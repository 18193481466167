@import "../../../../theme/colors";

.question-settings {
    padding: 0 35px;
    > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > span {
            font-family: "Nimbus Sans", sans-serif;
            font-size: 13px;
            line-height: 23px;
            color: $secondary-text-color;
            text-transform: uppercase;
        }
        padding-bottom: 20px;
    }
}
