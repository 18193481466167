@import "../../../../../theme/colors";

.main-question-edit {
  padding: 0 10px;

  .edit-box,
  .drop-down-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    label {
      font-family: "Nimbus Sans", sans-serif;
      font-size: 15px;
      font-weight: 400;
      color: $primary-text-color;
      transition: border 0.1s ease-in-out;
    }

    .weight {
      max-width: 150px;
    }
  }

  .salesforce-question-box {
      .drop-down-items {
        top: 80px;
      }
  }

  .edit-row {
    display: flex;

    .edit-box {
      width: 40%;
    }

    .relation-box {
      width: 60%;
      margin-bottom: 20px;

      .relation-header {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        margin: 0;
        align-items: flex-start;

        label {
          width: calc(100% - 35px);
          font-family: "Nimbus Sans", sans-serif;
          font-size: 15px;
          font-weight: 400;
          color: #173432;
          transition: border 0.1s ease-in-out;
        }

        button {
          width: 20px;
          padding: 0;
          font-size: 12px;
          line-height: normal;
        }
      }

      .related-question {
        padding: 5px;
        border: 1px solid $builder-border-color;
        border-radius: 2px;
        min-height: 55px;
        margin-top: 10px;

        span {
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 600;
          color: $secondary-text-color;
        }

        p {
          font-size: 12px;
          padding: 5px 0;
          color: $primary-text-color;
        }
      }

      div {
        margin-top: 8px;
      }

      button {
        transition: 0.3s;
        width: 100%;
      }

      button:hover {
        transition: 0.3s;
        background-color: $builder-main-color-lighter;
      }
    }
  }
}
.dynamic-relation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  .remove-dynamic-relation {
    align-self: flex-end;
  }
}
