@import "../../../../theme/colors";

.section-title {
    font-size: 16px;
    color: $secondary-text-color;
}

.survey-builder-questions {
    margin-top: 15px;

    .survey-builder-question {
        max-width: 365px;
        padding: 25px;
        background: white;
        margin: 0 auto 25px;
        border: 1px solid white;
        border-radius: 5px;
        position: relative;
    }

    .survey-builder-question:hover {
        transition: 0.2s;
        border: 1px solid $builder-border-color;
        cursor: pointer;
    }
}

.survey-section-loader {
    height: 100%;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 75px;
    }
}

.survey-builder-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 365px;
    margin: 0 auto;

    button {
        transition: 0.3s;
        padding: 0;
        border-radius: 0;
        border-right: 1px solid $secondary-button-color;
        width: 70px;

        span {
            font-size: 14px;
        }
    }

    button:hover {
        transition: 0.3s;
        background: $builder-main-color-lighter;
    }

    .action-first {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        width: 100px;

        span {
            margin-left: 5px;
        }
    }

    .action-last {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-right: none;
        width: 125px;
    }
}

.empty-survey-message {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding-top: 50px;
    color: $secondary-text-color;
}

.empty-questions-message {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: $secondary-text-color;
}
