.conditions {
    margin-left: 30px;
    margin-right: 30px;

    > .header {
        padding-left: 0;
        margin-top: 40px;
        margin-bottom: 40px;

        img {
            cursor: pointer;
        }

        .column-title {
            width: 110px;
            height: 23px;
            display: flex;
            align-items: center;

            > span {
                position: relative;
                top: 2px;
            }
        }
    }

    .drop-down-container {
        input[type="text"] {
            width: 100%;
        }

        width: 448px;

        > .drop-down > label {
            margin-bottom: 10px;
            display: inline-block;
        }
    }

    .condition-value {
        display: flex;
        align-items: center;
        margin-top: 7px;
        justify-content: space-between;
        margin-right: 10px;
    }

    .condition-item {
        margin-bottom: 15px;
        width: 457px;
    }

    .feedback-container {
        position: relative;
    }

    .condition-value input[type="text"] {
        width: 340px;
    }

    .feedback-container {
        .feedback {
            width: 448px;
            margin-top: 7px;
            padding-left: 49px;
        }

        div:last-child {
            position: relative;
            margin-bottom: 15px;
        }

        img {
            position: absolute;
            top: 17px;
            left: 10px;
            user-select: none;
            cursor: default;
        }

        .feedback::placeholder {
            color: #949494;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 23px;
            display: flex;
            align-items: center;
            letter-spacing: 0.0120588em;
        }
    }

    .condition-item .condition-value img {
        cursor: pointer;
    }
}
