.review-icon,
.disq-icon,
.eye-icon {
    width: 24px;
    height: 24px;
}

.close-image {
    height: 16px;
    width: 16px;
}

.disq-popup {
    width: 233px;
    height: 96px;
    background: #ffffff;
    box-shadow: 0 0 36px rgba(128, 154, 152, 0.25);
    border-radius: 4px;
    position: absolute;
    right: 0;
    z-index: 9;
    top: 73px;

    > ul {
        margin: 18px 18px 0;

        > li {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            user-select: none;
            cursor: pointer;

            > img {
                margin-left: 0 !important;
                margin-right: 4px;
            }
        }
    }
}

.disq-popup-numeric {
    width: 233px;
    height: 96px;
    background: #ffffff;
    box-shadow: 0 0 36px rgba(128, 154, 152, 0.25);
    border-radius: 4px;
    position: absolute;
    right: 0;
    z-index: 9;
    left: 35px;
    top: 365px;

    > ul {
        margin: 18px 18px 0;

        > li {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            user-select: none;
            cursor: pointer;

            > img {
                margin-left: 0 !important;
                margin-right: 4px;
            }
        }
    }
}

.answer-group {
    position: relative;
}

.salesforce-value {
    position: relative;
    top: -8px;

    .drop-down-container > .drop-down > img {
        top: 25px;
    }
}

div.tooltip {
    padding: 10px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(99, 117, 139, 0.35);

    .hidden {
        display: none;
    }

    > p {
        color: #646464;
        font-family: NunitoSans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
    }

    &::before,
    &::after {
        display: none !important;
    }
}

.additional-title {
    margin-top: 23px;
    margin-bottom: 10px;
}

.additional {
    margin-bottom: 10px;
}

.additional-controls {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 5px;
}

.additional-item {
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    > label {
        margin-top: 6px;
        margin-left: 10px;
    }
}
