.profile-form {
    margin: 0 auto;
    text-align: center;

    > {
        div {
            &:first-child {
                margin-top: 53px;
            }

            > .profile-image {
                width: 71px;
                height: 71px;
                background: #cfcfcf;
                background-blend-mode: normal;
                border: 1px solid #ffffff;
                border-radius: 35px;
                font-weight: 300;
                font-size: 30px;
                line-height: 41px;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: 0.0120588em;
                color: #57575c;
                position: relative;
                margin: 0 auto;
                cursor: pointer;

                > .add-image {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    background: #ffffff;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                }
            }
        }

        .form {
            width: 450px;
            margin: 29px auto;
            text-align: left;

            .form-item {
                margin-bottom: 31px;
                position: relative;

                label {
                    font-size: 15px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.0120588em;
                    color: #020202;
                }

                input {
                    height: 55px;
                    background: #f6f6f6;
                    border: 1px solid #e9e9e9;
                    box-sizing: border-box;
                    border-radius: 4px;
                    width: 100%;
                    margin-top: 9px;
                    padding: 16px 40px 16px 16px;
                    font-size: 15px;
                }
            }

            .save-button {
                background: #006485;
                border-radius: 36px;
                width: 215px;
                height: 44px;
                font-size: 14px;
                letter-spacing: 0.0120588em;
                text-align: center;
                outline: none;
                border: 0;
                margin-top: 15px;
                color: #ffffff;
            }

            .disabled {
                background: #eaeaee;
                color: #979797;
            }

            .sign-in {
                font-size: 14px;
                line-height: 28px;
                display: flex;
                align-items: center;
                letter-spacing: 0.0120588em;
                color: #959595;
                margin-top: 29px;
            }
        }

        .sign-in {
            label {
                width: 176px;
            }

            a {
                text-decoration-line: underline;
                color: #006485;
            }
        }

        .form > {
            .change-password {
                margin-top: 41px;
                margin-left: 24px;
                margin-bottom: 44px;

                > div {
                    text-decoration: none;
                    color: #006485;
                    cursor: pointer;
                    width: max-content;
                }
            }

            .delete-account {
                margin-top: 60px;
                margin-left: 24px;

                > div {
                    color: #ed501f;
                    text-decoration: none;
                    cursor: pointer;
                    width: max-content;
                }
            }
        }
    }
}

@media (max-width: 1250px) {
    .profile-form {
        width: 333px;

        > div:first-child {
            margin-top: 19px;
        }
    }

    .wrapper > section > .profile-page > {
        .center-column {
            box-sizing: border-box;
            margin: 0;
        }

        .left-column {
            width: 100%;
            height: auto;
            order: 1;
            border-bottom: 1px solid #efefef;
            box-sizing: border-box;
            padding-left: 34px;
        }
    }

    .profile-form .form {
        width: 333px;
    }
}

@media (max-width: 350px) {
    .profile-form .form {
        width: 290px;
    }
}
