.notification-container {
    position: absolute;
    top: 15px;
    right: 15px;
    min-width: 400px;
    padding: 15px;
    box-shadow: 0 3px 7px 1px #00000026;
    border-radius: 5px;

    .notification-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .notification-icon {
            margin-right: 8px;

            img {
                width: 35px;
            }
        }

        .notification-content {
            color: white;

            .notification-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                gap: 10px;

                h2 {
                    font-size: 18px;
                    font-weight: bold;
                }

                img {
                    width: 15px;
                }

                img:hover {
                    cursor: pointer;
                }
            }

            p {
                font-size: 15px;
                font-weight: 600;
            }
        }
    }
}

.error-notification {
    background: #f80b0b;
}

.success-notification {
    background: #0cbf0c;
}
