.survey-section .settings-buttons {
    > img {
        margin-right: 20px;
    }

    position: absolute;
    right: -100px;
    top: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 68px;
    height: 26px;
}
