.sliding-component {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
    height: 40px;

    &.active {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    }

    .sliding-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 16px;
        align-items: center;
        letter-spacing: 0.0120588em;
        color: #173432;
        margin-bottom: 10px;
        padding: 10px;
        background-color: #fff;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;

        &:hover,
        &.active {
            background-color: #f5f5f5;
        }

        .sliding-icon {
            font-size: 20px;
            font-weight: bold;
            margin-right: 5px;
        }
    }

    .sliding-body {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 10px;
        background-color: #f5f5f5;
        display: none;
        animation: slide-down 0.3s ease-in-out forwards;
        max-height: 220px;
        z-index: 99;
        overflow-y: auto;

        .sliding-answer {
            position: relative;
            display: flex;
            padding: 5px 0;
            cursor: pointer;
            border-bottom: 1px solid #ccc;

            .sliding-answer-number {
                font-size: 12px;
                font-weight: bold;
                margin-right: 10px;
            }

            .sliding-answer-title {
                display: inline-block;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 5px;
            }

            .sliding-answer-content {
                font-size: 14px;
            }

            .sliding-edit {
                position: absolute;
                right: -10px;

                transform: rotate(90deg);
                font-weight: bold;
            }
        }
    }

    &.active {
        .sliding-body {
            display: block;
        }
    }
}
