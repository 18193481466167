.languages {
    display: flex;
    padding: 40px 31px;
    background-color: #fff;
    height: calc(100vh - 150px);
    overflow-y: auto;
    .languages-table {
        flex-grow: 1;
        min-width: 528px;
        max-width: max-content;
        margin-right: 3px;
        .table-header {
            font-family: "SF Pro Text", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: flex-start;
            color: #262626;
            background: #f4f4f4;
            padding: 13px;
            min-height: 58px;
            .delete-icon {
                margin-left: auto;
            }
        }
        .accordion-icon {
            display: inline-block;
            margin-right: 16px;
            padding: 8px 0 0 8px;
            cursor: pointer;
            vertical-align: top;
        }
        .subsurvey-item,
        .section-item,
        .question-item,
        .group-item,
        .answer-item,
        .hint-item,
        .group-item,
        .group-hint,
        .group-answer,
        .note-content {
            border: 1px solid #f4f4f4;
            &:hover {
                background: rgba(0, 100, 133, 0.1);
            }
        }
        .subsurvey-item {
            padding: 13px;
        }
        .section-item {
            padding: 13px 5px 13px 42px;
        }
        .group-answer,
        .group-hint {
            padding: 13px 5px 13px 130px;
        }
        .question-item {
            padding: 13px 5px 13px 72px;
        }

        .group-item,
        .answer-item,
        .hint-item,
        .note-content {
            padding: 13px 5px 13px 116px;
        }
        div {
            font-family: "Nunito Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #262626;
            cursor: pointer;
            width: 528px;
        }
        span {
            cursor: auto;
        }
        .readonly-text {
            font-family: "Nunito Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #e0e0e0;
        }
        .edit-input {
            display: inline-block;
            font-family: "Nunito Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            padding: 8px 12px;
            width: 80%;
            min-height: 34px;
            height: 100%;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
        }
        .red-text {
            color: red;
        }
    }
    .add-container {
        display: flex;
        flex-direction: column;
        .add-language {
            font-family: "SF Pro Text", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            padding: 13px;
            color: #006485;
            width: 172px;
            height: 54px;

            background: #f2f2f2;
            cursor: pointer;
        }
        .grey-background {
            background: #fafafa;
            flex: none;
            flex-grow: 1;
        }
    }
}
